import React from 'react';
import { Vanpool } from '../shared/icons';
import Section from '../profile/Section';
import Callable from '../shared/Callable';
export default function ChangeMyCommutePopUp({ fineNewCommute }) {
  return (
    <div>
    <Section title="Vanpool" Icon={Vanpool}>
      <div className="upper">
        <div className="no-vanpool">
        Want to change your Commute?
      </div>
      </div>
      <div className="lower">
            <div className="search">
            To change your commute, please call <Callable phone="800-VAN-4-WORK" /> 
            </div>
          {/* <button className="button request" data-dtm-track="button|change_commute" onClick={() => fineNewCommute()}>
            Find New Commute
          </button> */}
      </div>
    </Section>

  </div>
  );
}
